var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("DashboardToolBar", { staticClass: "mb-4" }),
      !_vm.noquiz
        ? _c(
            "v-row",
            {
              staticClass: "d-flex",
              attrs: { align: "start", justify: "start" }
            },
            _vm._l(_vm.quizes, function(q) {
              return _c("quiz-card", { key: q.id, attrs: { quiz: q } })
            }),
            1
          )
        : _vm._e(),
      _vm.noquiz && !_vm.overlay
        ? _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [_vm.noquiz ? _c("NoQuiz") : _vm._e()],
            1
          )
        : _vm._e(),
      _c("Loading", {
        attrs: { loading: _vm.overlay, text: _vm.quizLoadingText }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }