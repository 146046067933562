var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "white", attrs: { flat: "" } },
    [
      _c("v-toolbar-title", { staticClass: "text-h5 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.welcomeMessage)),
        _vm.user.email
          ? _c("span", [_vm._v(_vm._s(_vm.userprofile.name))])
          : _vm._e()
      ]),
      _c("v-spacer"),
      _c(
        "v-btn",
        { attrs: { icon: "", large: "", color: "secondary" } },
        [_c("font-awesome-icon", { attrs: { icon: "bell", size: "lg" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }