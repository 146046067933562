var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "6", xl: "4" } },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "pa-4 px-lg-6 py-lg-4 quiz-card",
                    attrs: { elevation: hover ? 16 : 0 },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.goToEditPage.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "d-flex justify-space-between",
                        attrs: { align: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12",
                              sm: "6",
                              "align-self": "center"
                            }
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "text-h5 font-weight-bold linevisibility"
                              },
                              [_vm._v(" " + _vm._s(_vm.quiz.title) + " ")]
                            ),
                            _c(
                              "v-card-subtitle",
                              {
                                staticClass:
                                  "subtitle-1 mt-1 font-weight-medium linevisibility"
                              },
                              [_vm._v(_vm._s(_vm.quiz.description) + " ")]
                            )
                          ],
                          1
                        ),
                        _c("QuizCardCircle", {
                          attrs: {
                            label: "time",
                            value: Math.ceil(_vm.totalQuizTime / 60) + "m"
                          }
                        }),
                        _c("QuizCardCircle", {
                          attrs: {
                            label: "points",
                            value: "" + _vm.totalQuizPoints
                          }
                        }),
                        _c("QuizCardCircle", {
                          attrs: {
                            label: "questions",
                            value: "" + _vm.quizlength
                          }
                        }),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-space-between",
                            attrs: { cols: "3" }
                          },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass:
                                  "secondary--text font-weight-bold align-self-end",
                                attrs: {
                                  label: "",
                                  small: "",
                                  color: "accent2"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("humanizeSession")(_vm.quiz.type)
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-space-between",
                            attrs: { cols: "9" }
                          },
                          [
                            !_vm.user.email && _vm.quizlength >= 5
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "flex-grow-1 mr-2",
                                    attrs: {
                                      color: "success",
                                      to: "/guestsignup"
                                    }
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "play", pull: "left" }
                                    }),
                                    _vm._v(" Signup & Start")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "flex-grow-1 mr-2",
                                attrs: { color: "accent2", depressed: "" },
                                on: {
                                  "~click": function($event) {
                                    return _vm.goToEditPage.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "pen", pull: "left" }
                                }),
                                _vm._v(" Edit")
                              ],
                              1
                            ),
                            _c("SessionPopoverMenu", {
                              attrs: {
                                quiztype: _vm.quiz.type,
                                disabled: !_vm.user.email || _vm.quizlength <= 4
                              },
                              on: { createsession: _vm.initquiz }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }