<template>
  <v-toolbar flat class="white">
    <v-toolbar-title class="text-h5 font-weight-bold"
      >{{ welcomeMessage }}<span v-if="user.email">{{ userprofile.name }}</span></v-toolbar-title
    >
    <v-spacer></v-spacer>
    <v-btn icon large color="secondary">
      <font-awesome-icon icon="bell" size="lg"></font-awesome-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "DashboardToolBar",

  props: {},
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
      userprofile: (state) => state.user.userProfile,
    }),

    welcomeMessage() {
      const myDate = new Date()
      const hrs = myDate.getHours()
      return hrs < 12 ? "☀️ Good Morning " : hrs >= 12 && hrs <= 17 ? "☀️ Good Afternoon " : "✨ Good Evening "
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.v-toolbar {
  border-bottom: 2px solid var(--v-accent2-base) !important;
}
// .v-toolbar {
//   border: 1px solid var(--v-accent2-base);
//   border-left: 0.8rem solid var(--v-accent2-base) !important;
// }
</style>
