<template>
  <v-col cols="12" md="6" xl="4">
    <v-hover v-slot="{ hover }">
      <v-card class="pa-4 px-lg-6 py-lg-4 quiz-card" :elevation="hover ? 16 : 0" @click.prevent="goToEditPage">
        <v-row align="center" class="d-flex justify-space-between">
          <v-col cols="12" sm="6" align-self="center">
            <v-card-title class="text-h5 font-weight-bold linevisibility">
              {{ quiz.title }}
            </v-card-title>
            <v-card-subtitle class="subtitle-1 mt-1 font-weight-medium linevisibility"
              >{{ quiz.description }}
            </v-card-subtitle>
          </v-col>
          <QuizCardCircle :label="'time'" :value="`${Math.ceil(totalQuizTime / 60)}m`" />
          <QuizCardCircle :label="'points'" :value="`${totalQuizPoints}`" />
          <QuizCardCircle :label="'questions'" :value="`${quizlength}`" />
          <v-col cols="3" class="d-flex justify-space-between">
            <v-chip label small color="accent2" class="secondary--text font-weight-bold align-self-end">{{
              quiz.type | humanizeSession
            }}</v-chip>
          </v-col>
          <v-col cols="9" class="d-flex justify-space-between">
            <v-btn color="success" v-if="!user.email && quizlength >= 5" to="/guestsignup" class="flex-grow-1 mr-2">
              <font-awesome-icon icon="play" pull="left"></font-awesome-icon>
              Signup & Start</v-btn
            >
            <v-btn class="flex-grow-1 mr-2" color="accent2" @click.once="goToEditPage" depressed>
              <font-awesome-icon icon="pen" pull="left"></font-awesome-icon>
              Edit</v-btn
            >
            <SessionPopoverMenu
              :quiztype="quiz.type"
              :disabled="!user.email || quizlength <= 4"
              @createsession="initquiz"
            ></SessionPopoverMenu>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
import QuizCardCircle from "@/components/quiz/QuizCardCircle.vue"
import SessionPopoverMenu from "@/components/quiz/SessionPopoverMenu.vue"
import { mapMutations, mapState } from "vuex"

export default {
  name: "quiz-card",
  components: {
    QuizCardCircle,
    SessionPopoverMenu,
  },
  data() {
    return {
      snacktext: null,
      quizpin: null,
    }
  },
  props: ["quiz"],
  computed: {
    ...mapState({
      user: (state) => state.user.currentUser,
    }),
    quizlength() {
      return this.quiz.questions?.length
    },
    totalQuizTime() {
      return this.quiz.questions?.length ? this.quiz.questions.map((e) => e.timer).reduce((total, a) => total + a) : 0
    },
    totalQuizPoints() {
      return this.quiz.questions?.length ? this.quiz.questions.map((e) => e.points).reduce((total, a) => total + a) : 0
    },
  },
  methods: {
    ...mapMutations({ showAlert: "SHOW_SNACKBAR" }),

    goToEditPage() {
      this.$router.push({ name: "EditQuiz", params: { id: this.quiz.id } })
    },

    initquiz(sessionname, emaillist) {
      this.$eventBus.$emit("startquiz", { ...this.quiz, sessionname: sessionname }, emaillist)
    },

    deletequiz() {
      this.$eventBus.$emit("deletequiz", this.quiz.id)
    },
  },
  filters: {
    humanizeSession: function (value) {
      const session_type = { S: "Simple", L: "Live", C: "Classic" }
      return session_type[value]
    },
  },
}
</script>

<style lang="scss" scoped>
.linevisibility {
  line-height: 1.5rem;
  height: 3rem;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.quiz-card {
  // border: 1px solid white;
  border: 1px solid var(--v-accent2-base);
  border-left: 0.8rem solid var(--v-accent2-base);
  &:hover {
    border: 1px solid var(--v-secondary-base);
    border-left: 0.8rem solid var(--v-secondary-base);
  }
}
</style>
