<template>
  <v-row align="center" justify="center" class="align-stretch">
    <v-col cols="12" sm="6" md="4" v-for="card in cards" :key="card.title">
      <v-hover v-slot="{ hover }">
        <v-card
          :elevation="hover ? 4 : 0"
          height="100%"
          width="100%"
          :color="hover ? 'secondary white--text' : ''"
          @click.once="handleClick(card)"
        >
          <v-col cols="12" class="d-flex flex-md-row justify-space-between">
            <v-card-title class="text-h6 text-sm-h5 font-weight-bold">{{ card.title }}</v-card-title>
            <v-img
              :max-height="$vuetify.breakpoint.smAndUp ? 120 : 60"
              :max-width="$vuetify.breakpoint.smAndUp ? 90 : 60"
              contain
              :src="require('@/assets/' + card.image + '')"
            ></v-img>
          </v-col>
          <div>
            <v-card-subtitle class="subtitle-1 pa-6 text-sm-h6 font-weight-medium">{{ card.subtitle }}</v-card-subtitle>
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NoQuizTypeCard",
  data() {
    return {
      cards: [
        {
          title: "Live Quiz",
          short_title: "Live",
          subtitle: "Presenter controls flow of questions and participants play live",
          image: "quiz.png",
          type: "L",
        },
        {
          title: "Classic Quiz",
          short_title: "Classic",
          subtitle: "Just an online test with time and live results dashboard",
          image: "poll.png",
          type: "C",
        },
        {
          title: "Simple Quiz",
          short_title: "Simple",
          subtitle: "Quiz with no time pressure. Participants answer at their own pace.",
          image: "poll.png",
          type: "S",
        },
      ],
    }
  },
  methods: {
    handleClick(card) {
      this.$router.push({ name: "Create", params: { type: card.type } })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  cursor: pointer;
}
</style>
