<template>
  <v-row align="center" justify="center" class="px-12">
    <v-col cols="12" md="7" sm="6">
      <h3 class="text-h4 font-weight-bold">Get started with your first quiz!</h3>
      <h4 class="text-h5 font-weight-medium">
        A quiz or survey after your corporate presentation or a fun Q&A session after a long class.
      </h4>
    </v-col>
    <v-col cols="12" md="5" sm="6">
      <lottie-player
        src="https://assets5.lottiefiles.com/packages/lf20_li7l71bd.json"
        background="transparent"
        speed="1"
        autoplay
        loop
      ></lottie-player>
    </v-col>
    <NoQuizTypeCard></NoQuizTypeCard>
  </v-row>
</template>

<script>
import NoQuizTypeCard from "@/components/quiz/NoQuizTypeCard.vue"
export default {
  name: "NoQuiz",
  components: {
    NoQuizTypeCard,
  },
}
</script>

<style lang="scss" scoped></style>
