var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "px-12", attrs: { align: "center", justify: "center" } },
    [
      _c("v-col", { attrs: { cols: "12", md: "7", sm: "6" } }, [
        _c("h3", { staticClass: "text-h4 font-weight-bold" }, [
          _vm._v("Get started with your first quiz!")
        ]),
        _c("h4", { staticClass: "text-h5 font-weight-medium" }, [
          _vm._v(
            " A quiz or survey after your corporate presentation or a fun Q&A session after a long class. "
          )
        ])
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "5", sm: "6" } },
        [
          _c("lottie-player", {
            attrs: {
              src:
                "https://assets5.lottiefiles.com/packages/lf20_li7l71bd.json",
              background: "transparent",
              speed: "1",
              autoplay: "",
              loop: ""
            }
          })
        ],
        1
      ),
      _c("NoQuizTypeCard")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }