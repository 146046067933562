var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      staticClass: "pa-sm-4 d-flex flex-column align-center",
      attrs: { cols: "4", sm: "2" }
    },
    [
      _c("span", { staticClass: "text-center subtitle-1" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _c("v-avatar", { attrs: { color: "accent2", size: "48" } }, [
        _c("span", { staticClass: "subtitle-1 font-weight-bold" }, [
          _vm._v(_vm._s(_vm.value))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }