<template>
  <v-container>
    <DashboardToolBar class="mb-4"></DashboardToolBar>
    <!-- <quiz-type-card :noquiz="noquiz" v-if="!noquiz"> </quiz-type-card> -->
    <!-- <v-row class="d-flex justify-space-around">
      <v-col cols="4">
        <v-card class="pa-4" outlined>
          <v-card-title>Total Quizzes</v-card-title>
          <v-card-subtitle>{{ quizes.length }}</v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-4" outlined><v-card-title>Game Plays</v-card-title></v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-4" outlined><v-card-title>Game Plays</v-card-title></v-card>
      </v-col>
    </v-row> -->
    <v-row align="start" justify="start" v-if="!noquiz" class="d-flex">
      <quiz-card v-for="q in quizes" :key="q.id" :quiz="q" />
    </v-row>
    <v-row align="center" justify="center" v-if="noquiz && !overlay">
      <NoQuiz v-if="noquiz"></NoQuiz>
    </v-row>
    <Loading :loading="overlay" :text="quizLoadingText" />
  </v-container>
</template>

<script>
import QuizCard from "@/components/quiz/QuizCard"
import NoQuiz from "@/components/quiz/NoQuiz"
import { mapState } from "vuex"
// import QuizTypeCard from "@/components/quiz/QuizTypeCard.vue"
import DashboardToolBar from "@/components/quiz/DashboardToolBar.vue"
import { fetchQuizzes } from "@/api/QuizApi"

export default {
  name: "Quizzes",
  data() {
    return {
      overlay: true,
      quizLoadingText: "Fecthing your quizzes!",
    }
  },
  components: {
    QuizCard,
    NoQuiz,
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/common/Loading.vue"),
    // QuizTypeCard,
    DashboardToolBar,
  },
  computed: {
    ...mapState({
      quizes: (state) => state.quiz.quizzes,
      user: (state) => state.user.currentUser,
    }),
    noquiz() {
      return !this.quizes?.length
    },
  },
  async mounted() {
    await fetchQuizzes(this.user.uid).then(() => {
      this.overlay = false
    })
  },
}
</script>
<style lang="scss" scoped>
#quizzes {
  min-height: 100vh;
}
</style>
