<template>
  <v-col cols="4" sm="2" class="pa-sm-4 d-flex flex-column align-center">
    <span class="text-center subtitle-1">{{ label }}</span>
    <v-avatar color="accent2" size="48"
      ><span class="subtitle-1 font-weight-bold">{{ value }}</span>
    </v-avatar>
  </v-col>
</template>

<script>
export default {
  name: "QuizCardCircle",
  props: {
    value: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
  },
}
</script>

<style lang="scss" scoped>
.test {
  color: red;
  border-radius: 50%;
}
</style>
